import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import LoadIframe from "./LoadIframe";
import { toggleRialtoMode } from "../store/actions/ui";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { clearGeneticState } from '../store/actions/genetics';
import {
  getPracticePureAccount,
  getRialtoTermsAndCondition,
} from "../store/actions/practiceDashboard";
import { acceptTermsConditions } from "../store/actions/auth";
import TermsAndConditions from "../../src/Pages/TermsAndConditions";
import PureAccountModelVd from "../../src/components/PureAccountModelVd";

export const RialtoPage = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  console.log("location---------->", location);
  // const [app_url, setApp_url] = useState('');

  // redux state variable
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const token = useSelector((state) => state.auth.token);
  const appToken = useSelector((state) => state.auth.appToken);
  const userType = useSelector((state) => state.auth.userType);
  const email = useSelector((state) => state.auth.email);
  const firstName = useSelector((state) => state.auth.firstName);
  const lastName = useSelector((state) => state.auth.lastName);
  const phoneNumber = useSelector((state) => state.auth.phoneNumber);
  const currentUserId = useSelector((state) => state.auth.currentUserId);
  const rialtoState = useSelector((state) => state.ui.rialtoState);
  const practitioner_id = useSelector((state) => state.auth.practitioner_id);
  const is_staff = useSelector((state) => state.auth.isStaff);
  const staff_id = useSelector((state) => state.auth.currentUserId);
  const language = i18n.language;
  const vd_path = useSelector((state) => state.practiceDashboard.vd_path);
  const userInfoTk = useSelector((state) => state.auth.userInfoTk);

  const dispatch = useDispatch();
  // redux actions
  const toggleRialtoModeAction = (pld) => dispatch(toggleRialtoMode(pld));
  const RIALTO_WEB_HOST = process.env.REACT_APP_RIALTO_WEB_HOST;
  const { path, script, scriptId, orderId, browseCatalog, reportId, fromReportPage, fromViewSuppRecomm } =
    location.state || {};

  const browse_catalog = `&browse_catalog=${browseCatalog}`;
  const phone = `&phone=${phoneNumber}`;
  const tenant_id = `&tenant_id=${currentPracticeID}`;
  const app_token = `?app_token=${appToken}`;
  const host = `${RIALTO_WEB_HOST}${path || vd_path}`;
  const subdomain = `&subdomain=${currentPractice}`;
  const user_token = `&user_token=${token}`;
  const first_name = `&first_name=${firstName}`;
  const last_name = `&last_name=${lastName}`;
  const user_email = `&user_email=${email}`;
  const user_type = `&user_type=${userType}`;
  const parent_url = `&parent_url=${window.location.origin}`;
  const report_id = `&profile_id=${reportId}`;
  const client_name = `&client_name=${process.env.REACT_APP_CLIENT_NAME}`;
  const staff_params = `&is_staff=${is_staff}&staff_id=${staff_id}`;
  const lang = `&language=${language}`;
  const user_info_tk = `&user_info_tk=${userInfoTk}`;

  let from_report_page = "";
  let from_view_supp_recomm = "";
  let script_id = "";
  let order_id = "";
  if (script) {
    script_id = `&script_id=${script.scriptId}`;
  }
  if (scriptId) {
    script_id = `&script_id=${scriptId}`;
  }
  if (orderId) {
    order_id = `&order_id=${orderId}`;
  }
  let user_id = "";
  if (userType === "Patient") {
    user_id = `&patient_id=${currentUserId}&practitioner_id=${
      practitioner_id || ""
    }`;
  } else {
    user_id = `&practitioner_id=${practitioner_id}`;
  }

  if (fromReportPage) { from_report_page = `&from_report_page=${fromReportPage}`};
  if (fromViewSuppRecomm) { from_view_supp_recomm = `&from_view_supp_recomm=${fromViewSuppRecomm}`};
  // const practitioner_id = `&practitioner_id=${currentPracticeID}`
  const app_url =
    host +
    app_token +
    user_token +
    user_email +
    user_type +
    user_id +
    tenant_id +
    staff_params +
    subdomain +
    script_id +
    order_id +
    first_name +
    last_name +
    phone +
    report_id +
    browse_catalog +
    client_name +
    lang +
    from_report_page +
    from_view_supp_recomm +
    user_info_tk +
    parent_url;

  const pathname = location.pathname;
  const getPracticePureAccountAction = (
    token,
    currentPracticeID,
    currentPractice
  ) =>
    dispatch(getPracticePureAccount(token, currentPracticeID, currentPractice));
  const acceptTermsConditionsAction = (
    token,
    currentPracticeID,
    currentPractice,
    type
  ) =>
    dispatch(
      acceptTermsConditions(token, currentPracticeID, currentPractice, type)
    );
  const getRialtoTermsAction = (token, currentPracticeID, currentPractice) =>
    dispatch(
      getRialtoTermsAndCondition(token, currentPracticeID, currentPractice)
    );
  const [showPureAccountModal, setShowPureAccountModal] = useState(false);
  const [showRialtoTermModal, setShowRialtoTermModal] = useState(false);
  const [rialtoTerms, setRialtoTerms] = useState("");
  const [enableECommerce, setEnableECommerce] = useState(false);
  const [pureAccountNumber, setPureAccountNumber] = useState("");

  const getPracticePan = () => {
    getPracticePureAccountAction(
      token,
      currentPracticeID,
      currentPractice
    ).then((response) => {
      if (!response.error) {
        setEnableECommerce(response?.payload?.enable_e_commerce);
        setPureAccountNumber(response?.payload?.pure_account_number);
        if (
          language === "en" &&
          !response?.payload?.enable_e_commerce &&
          !response?.payload?.pure_account_number
        ) {
          setShowPureAccountModal(true);
        }
      }
    });
  };

  const getRialtoTerms = () => {
    getRialtoTermsAction(token, currentPracticeID, currentPractice).then(
      (response) => {
        if (response?.payload?.success) {
          setShowRialtoTermModal(response?.payload?.agree_rialto_term);
          setRialtoTerms(response?.payload?.rialto_terms?.content);
        }
      }
    );
  };

  const handleAcceptTerms = (e) => {
    acceptTermsConditionsAction(
      token,
      currentPracticeID,
      currentPractice,
      e
    ).then((response) => {
      if (!response.error) {
        if (e === "rialto") {
          setShowRialtoTermModal(false);
        }
      }
    });
  };

  const pureAccountUpdateCall = (response) => {
    setShowRialtoTermModal(response?.agree_rialto_term);
    setRialtoTerms(response?.rialto_terms?.content);
    setShowPureAccountModal(false);
  };

  // useEffect(() => {
  //   if (path)
  //     setApp_url(host +
  //       app_token +
  //       user_token +
  //       user_email +
  //       user_type +
  //       user_id +
  //       tenant_id +
  //       subdomain +
  //       script_id +
  //       order_id +
  //       first_name +
  //       last_name +
  //       phone +
  //       report_id +
  //       browse_catalog +
  //       parent_url);

  //   console.log('currnet rialtoState', rialtoState)
  // }, [rialtoState]);

  useEffect(() => {
    if (pathname === "/practice/rialto_management_new") {
      getPracticePan();
      getRialtoTerms();
    }
  }, []);

  useEffect(() => {
    console.log("insideRialto-------->");
    toggleRialtoModeAction(true);

    return () => {
      console.log("outsideRialto-------->");
      toggleRialtoModeAction(false);
    };
  }, []);

  return (
    <div style={{ minHeight: "100vh" }}>
      <Helmet>
        <title>
          {" "}
          {language !== "en_gb" ? "PureInsight™" : "PureGenomics®"} - Rialto{" "}
        </title>
      </Helmet>
      <div className="container-xl pt-5">
        <PureAccountModelVd
          showModal={showPureAccountModal}
          onCancel={() => setShowPureAccountModal(false)}
          token={token}
          currentPracticeID={currentPracticeID}
          currentPractice={currentPractice}
          isLogin={true}
          onRefresh={(response) => pureAccountUpdateCall(response)}
          enableClose={false}
        />
        {showRialtoTermModal && (
          <div>
            <TermsAndConditions
              showModal={true}
              content={rialtoTerms}
              showClose={false}
              showButton={true}
              acceptTerms={() => handleAcceptTerms("rialto")}
              title={"VIRTUAL DISPENSARY TERMS OF USE"}
            />
          </div>
        )}
      </div>
      {!showRialtoTermModal && !showPureAccountModal && app_url && (
        <LoadIframe
          src={app_url}
          height="100%"
          width="100%"
          scrolling="no"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        />
      )}
    </div>
  );
};

export default RialtoPage;
