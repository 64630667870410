import React, { useEffect, useRef, useState } from "react";
import PgAccordion from "../PgAccordion";
import ExternalSitePrompt from "../ExternalSitePrompt";
import { FRProviderFAQData, CAProviderFAQData, CAProviderFAQTitle, FRProviderFAQTitle } from './ca_faq_data'
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";
import { isExternal } from "../../../methods";

function CAProviderFAQ() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [showExternalSiteModal, setShowExternalSiteModal] = useState(false);
  const [externalSiteURL, setExternalSiteURL] = useState("");
  const faqContainerRef = useRef(null);
  const faqData = (i18n.language === "fr" ? FRProviderFAQData : CAProviderFAQData) || [];



  const handleExternalLinkClick = (url) => {
    setExternalSiteURL(url);
    setShowExternalSiteModal(true);
  };

  const triggerMailto = () => {

  };

  const excludedProtocols = ["mailto:", "tel:", "sms:", "ftp:", "news:", "irc:"];
  const handleClick = (event) => {
    const target = event.target;
    const anchor = target.closest("a");
    if (anchor && anchor.href) {
      const href = anchor.getAttribute("href");
      if (excludedProtocols.some(protocol => href.startsWith(protocol))) return;
      if (isExternal(href)) {
        event.preventDefault();
        handleExternalLinkClick(href);
      } else {
        event.preventDefault();
        navigate(href);
      }
    }
  };

  useEffect(() => {
    const container = faqContainerRef.current;

    if (container) {
      container.addEventListener("click", handleClick);
    }

    return () => {
      if (container) {
        container.removeEventListener("click", handleClick);
      }
    };
  }, [navigate]);

  return (
    <div className="my-5">
      {showExternalSiteModal && (
        <ExternalSitePrompt
          showModal={showExternalSiteModal}
          externalSiteLink={externalSiteURL}
          onCancel={() => setShowExternalSiteModal(false)}
        />
      )}
      <div className="container-xxl" ref={faqContainerRef}>
        <div class="pgs-title my-5 lh-base" dangerouslySetInnerHTML={{ __html: i18n.language === "fr" ? FRProviderFAQTitle : CAProviderFAQTitle }} />
        {faqData.map((section, sectionIndex) => {
          const { faqs, section: sectionTitle } = section || [];
          return (
            <div key={sectionIndex}>
              <div className="pgs-heading mt-5 mb-3 textc-primary" dangerouslySetInnerHTML={{ __html: sectionTitle }} />
              {faqs.map((faq, faqIndex) => (
                <PgAccordion key={faqIndex} question={faq.question}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(faq.answer),
                    }}
                  />
                </PgAccordion>
              ))}
            </div>
          )
        })}
      </div>
    </div>
  );
}

export default CAProviderFAQ;
