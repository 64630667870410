import LifestyleRecommendation from "./LifeStyleRecommendation";
import DietRecommendation from "./DietRecommendation";
import SupplementRecommendation from "./SupplementRecommendation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePatientReport } from "../../../../store/actions/patientReport";
import {
  createRecommendation,
  deleteRecommendation,
  releaseReportToPatient,
  savePatientNotes,
  saveReorderRecommendation,
  updatePG4ReportData,
} from "../../../../store/actions/pg4report";
import PatientRecommendationContainer from "./Patient/PatientRecommendationContainer";
import { getCurrentDate } from "../../../../methods/constant";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { updateRialtoState } from "../../../../store/actions/ui";
import DeleteRecommendationModal from "../DeleteRecommendationModal";
import "./personalizedRecommendation.scss";
import AddRecommendation from "./AddRecommendation";
import { recommendations_cat_key_map } from "../../../../constant";
import { t } from "i18next";

function RecommendationContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // state
  const [focusAreas, setfocusAreas] = useState([]);
  const [showConfirmation, setshowConfirmation] = useState(false);
  const [currentSKU, setcurrentSKU] = useState("");
  const [currentReportData, setcurrentReportData] = useState(null);
  const [showAddRecommendation, setshowAddRecommendation] = useState(false);
  const [recModalContext, setrecModalContext] = useState("");

  // redux state
  const token = useSelector((state) => state.auth.token);
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const reportData = useSelector((state) => state.pg4Report?.reportData);
  const userType = useSelector((state) => state.auth.userType);
  const rilatoState = useSelector((state) => state.ui.rialtoState);
  // actions
  const saveReportNotesAction = (data) =>
    dispatch(
      savePatientNotes({ currentPracticeID, currentPractice, token, data })
    );
  const createRecommendationAction = (data) =>
    dispatch(
      createRecommendation({ currentPracticeID, currentPractice, token, data })
    );
  const saveReorderRecommendationAction = (data) =>
    dispatch(
      saveReorderRecommendation({
        currentPracticeID,
        currentPractice,
        token,
        data,
      })
    );
  const releaseToPatientAction = (data) =>
    dispatch(
      releaseReportToPatient({
        currentPracticeID,
        currentPractice,
        token,
        data,
      })
    );
  const deleteRecommendationAction = (data) =>
    dispatch(
      deleteRecommendation({ currentPracticeID, currentPractice, token, data })
    );
  const updatePG4ReportDataAction = (data) =>
    dispatch(updatePG4ReportData(data));
  const updateRialtoStateAction = (payload) =>
    dispatch(updateRialtoState(payload));

  useEffect(() => {
    setfocusAreas(() => {
      return reportData?.focus_areas?.slice(0, 3)?.map((trait) => ({
        key: trait.focus_id_key?.toLowerCase(),
        value: trait.focus_id,
      }));
    });
    setcurrentReportData(reportData);
  }, [reportData]);

  const onSavePatientNotes = (note) => {
    if (userType === "User")
      saveReportNotesAction({ report_id: reportData?.report_id, ...note });
  };

  const onReorderRecommendation = (data) => {
    if (userType === "User")
      saveReorderRecommendationAction({
        report_id: reportData?.report_id,
        ...data,
      });
  };

  const onDeleteRecommendation = (
    recommendation_ids,
    recommendation_category
  ) => {
    setcurrentSKU({ recommendation_ids, recommendation_category });
    setshowConfirmation(true);
  };
  const __onDeleteRecommendation = async (
    recommendation_ids,
    recommendation_category
  ) => {
    const response = await deleteRecommendationAction({
      recommendation_ids: currentSKU.recommendation_ids,
      recommendation_category: currentSKU.recommendation_category,
      report_id: reportData.report_id,
    });
    if (!response.error) {
      const current_category =
        recommendations_cat_key_map[currentSKU.recommendation_category];

      const filterdRecommedations = reportData[
        current_category
      ]?.recommendations?.filter((dItem) => {
        return !currentSKU?.recommendation_ids.includes(
          dItem.recommendation_id
        );
      });
      const updatedReportData = {
        ...reportData,
        [current_category]: {
          ...reportData[current_category],
          recommendations: filterdRecommedations,
        },
      };
      updatePG4ReportDataAction(updatedReportData);
    }
    setshowConfirmation(false);
  };
  const onReleaseToPatient = () => {
    releaseToPatientAction({
      report_id: Number(reportData?.report_id),
      current_date: getCurrentDate(),
    }).then((response) => {
      if (!response.error) {
        toast.success(t("pg4_report_txt_11"));
        navigate("/practice/patients");
      }
    });
  };

  const onNavigateToViewRecommededScript = () => {
    let path =
      userType == "Patient" && reportData?.script?.script_placed_status !== true
        ? "/supplement_suggestions"
        : "/view_recommended_script";

    const state = {
      path: path,
      reportId: reportData.report_id,
      browseCatalog: false,
      scriptId:
        reportData?.script?.script_id || reportData?.script?.draft_script_id,
      userType: userType,
      fromReportPage: true,
    };
    updateRialtoStateAction({
      ...rilatoState,
      ...state,
    });
    userType == "Patient"
      ? navigate("/patient/supplement_catalog", { state })
      : navigate("/practice/supplement_catalog", { state });
  };

  const onNagivateToSupplements = () => {
    let script_id =
      reportData.script.draft_script_id || reportData.script.script_id;
    const state = {
      path: `/tenants/${currentPracticeID}/patients/${reportData.patient_id}/rialto_service`,
      reportId: reportData.report_id,
      scriptId: script_id,
      browseCatalog: false,
    };
    updateRialtoStateAction({
      ...rilatoState,
      ...state,
    });
    navigate("/practice/supplement_catalog", { state });
  };

  const onClickShowAddRecommendation = (context) => {
    setshowAddRecommendation(true);
    setrecModalContext(context);
  };

  return (
    <div className="py-5 my-5">
      {/* delete warning modal */}
      {showConfirmation && (
        <DeleteRecommendationModal
          showModal={showConfirmation}
          onConfirm={__onDeleteRecommendation}
          onCancel={() => setshowConfirmation(false)}
        />
      )}

      {/* add recommendation modal */}

      {showAddRecommendation && (
        <AddRecommendation
          report_id={currentReportData.report_id}
          show={showAddRecommendation}
          onCancel={() => setshowAddRecommendation(false)}
          focusAreas={focusAreas}
          context={recModalContext}
          onCreateRecommendation={createRecommendationAction}
        />
      )}
      <div className="d-flex flex-wrap justify-content-between align-items-center">
        <h1 className="pg4-text__title text_primary my-4">
          {t("personalized_recommendations")}
        </h1>
        {!reportData?.is_shared && (
          <div className="d-none d-lg-block">
            <button
              className="pg4-btn pg4-btn_primary px-5"
              onClick={onReleaseToPatient}
            >
              {t("release_to_patient")}
            </button>
          </div>
        )}
      </div>
      {userType === "User" ? (
        <div className="row justify-content-between">
          <section className="col-12 col-md-6 col-xl-4 ">
            <DietRecommendation
              data={currentReportData?.diet_recommedations || {}}
              focusAreas={focusAreas}
              userType={userType}
              onSavePatientNotes={onSavePatientNotes}
              onReorderRecommendation={onReorderRecommendation}
              onDeleteRecommendation={onDeleteRecommendation}
              onClickShowAddRecommendation={onClickShowAddRecommendation}
            />
          </section>
          <section className="col-12 col-md-6 col-xl-4">
            <LifestyleRecommendation
              data={currentReportData?.lifestyle_recommedations || {}}
              focusAreas={focusAreas}
              userType={userType}
              onSavePatientNotes={onSavePatientNotes}
              onReorderRecommendation={onReorderRecommendation}
              onDeleteRecommendation={onDeleteRecommendation}
              onClickShowAddRecommendation={onClickShowAddRecommendation}
            />
          </section>
          <section className="col-12 col-md-6 col-xl-4">
            <SupplementRecommendation
              data={currentReportData?.supplement_recommendations || {}}
              reportData={currentReportData}
              focusAreas={focusAreas}
              userType={userType}
              onSavePatientNotes={onSavePatientNotes}
              onReorderRecommendation={onReorderRecommendation}
              onDeleteRecommendation={onDeleteRecommendation}
              onNagivateToSupplements={onNagivateToSupplements}
              onNavigateToViewRecommededScript={
                onNavigateToViewRecommededScript
              }
            />
          </section>
        </div>
      ) : (
        <PatientRecommendationContainer
          reportData={currentReportData}
          focusAreas={focusAreas}
          onNavigateToViewRecommededScript={onNavigateToViewRecommededScript}
        />
      )}
      {!reportData?.is_shared && (
        <div className="text-center my-4">
          <button
            className="pg4-btn pg4-btn_primary px-5"
            onClick={onReleaseToPatient}
          >
            {t("release_to_patient")}
          </button>
        </div>
      )}
    </div>
  );
}

export default RecommendationContainer;
