import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ReactComponent as PgLogo } from "../assets/pg_logo_1.svg";
import { ReactComponent as PiLogo } from "../assets/PureInsight_Logo_KO_u2.svg";
import { ReactComponent as Hamburger } from "../assets/hamburger.svg";
import Pg3LogoWhite from "../assets/PG_Logo.svg";
import { ReactComponent as PgLogoBlue } from "../assets/PG_Logo.svg";
import Pi3LogoWhite from "../assets/PureInsight_Logo_RGB_u1.svg";
import { ReactComponent as PiLogoBlue } from "../assets/PureInsight_Logo_RGB_u1.svg";
import { toggleSidebar } from "../methods/sidenav";
import NavButtons from "./NavButtons";
import { clearCookie } from "../methods";
import { logoutAction } from "../methods/auth_methods";
import "../styles/header.css";
import { Dropdown } from "react-bootstrap"; // Added import
import LanguageMenu from "./LanguageMenu";
import useLanguageConditions from "../hooks/useLanguageConditions";

export default function Header() {
  const { t, i18n } = useTranslation();
  const { isCA } = useLanguageConditions()
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // local state
  const isSmallDevice = window.innerWidth < 986;
  const [inHomePage, setInHomePage] = useState(true);
  const [navBarWidth, setnavBarWidth] = useState(
    isSmallDevice ? "100%" : "75vw"
  );
  const [header, setHeader] = useState(
    isSmallDevice ? "header-white" : "header-transparent"
  );

  // redux state
  const isAuth = useSelector((state) => state.auth.mfaEnabled);
  const mfaEnabled = useSelector((state) => state.auth.mfaEnabled);
  const userType = useSelector((state) => state.auth.userType);
  const token = useSelector((state) => state.auth.token);
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentPracticeID = useSelector((state) => state.auth.currentPracticeID);
  const currentPG2UserId = useSelector((state) => state.auth.currentPG2UserId);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (location.pathname !== "/") {
      setInHomePage(false);
      setnavBarWidth(isSmallDevice ? "100%" : "100vw");
    } else {
      if (!isSmallDevice) setnavBarWidth("75vw");
      setInHomePage(true);
    }
  }, [location]);

  const handleScroll = () => {
    if (window.innerWidth > 986)
      if (window.scrollY < 22) {
        return setHeader("header-transparent");
      } else if (window.scrollY > 20) {
        return setHeader("header-white");
      }
  };

  const goToDashboard = () => {
    navigate(
      `/${userType === "AdminUser"
        ? "admin"
        : userType === "User"
          ? "practice"
          : "patient"
      }/dashboard`
    );
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <nav
      id={header}
      className="bgc-primary position-fixed w-100 p-1 bg-white"
      style={{ zIndex: 10, top: 0 }}
      role="navigation"
      aria-label="Main Navigation"
    >
      <div className="container-fluid p-md-3 p-1 px-3 mx-lg-1">
        <div className="container-xxl d-flex justify-content-between align-items-center">
          <div className="d-md-none my-auto">
            <button
              onClick={() => toggleSidebar()}
              aria-label="Toggle Sidebar Menu"
              aria-expanded="false" // Update dynamically if sidebar state is managed
              aria-controls="sidebar-menu" // Ensure the sidebar has this ID
              className="all-unset "
            >
              {/* <Hamburger aria-hidden="true" focusable="false" /> */}
              <span className="hamburger hamburger-one mt-3"></span>
              <span className="sr-only">Toggle Sidebar Menu</span>
            </button>
          </div>
          <div
            className="m-auto m-md-0 pg-logo cursor-pointer d-flex"
            onClick={() => navigate("/")}
            role="link"
            tabIndex={0}
            aria-label="Go to homepage"
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                navigate("/");
              }
            }}
          >
            <div className="d-none d-md-block">
              {header === "header-transparent" && window.location.pathname === "/" ? (
                i18n.language === "en_gb" ? (
                  <PgLogo fontSize="100px" aria-hidden="true" />
                ) : (
                  <PiLogoBlue style={{ width: "250px" }} fontSize="100px" aria-hidden="true" />
                )
              ) : i18n.language === "en_gb" ? (
                <PgLogoBlue fontSize="100px" aria-hidden="true" />
              ) : (
                <PiLogoBlue style={{ width: "250px" }} fontSize="100px" aria-hidden="true" />
              )}
            </div>
            <div className="d-block d-md-none">
              {i18n.language === "en_gb" ? (
                <img src={Pg3LogoWhite} alt="Logo" style={{ height: "50px" }} />
              ) : (
                <PiLogoBlue aria-hidden="true" />
              )}

            </div>
          </div>
          {(!isAuth && isCA) && (
            <div className="ms-3 d-md-none">
              <LanguageMenu />
            </div>
          )}

          <div className="d-none d-md-flex align-items-center">
            {isAuth && mfaEnabled ? (
              <div
                onClick={logoutAction}
                className="pg-text textc-primary d-inline-block me-3 cursor-pointer fw-bold my-auto"
                id="provider-registration"
                role="button"
                tabIndex={0}
                aria-label="Sign Out"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    logoutAction();
                  }
                }}
              >
                {t("header_btn_1")}
              </div>
            ) : (
              <div className="d-inline-block text-end me-3">
                <div className="d-flex flex-column align-items-end">
                  <NavLink
                    to="/hcp/registration"
                    className="fw-bold textc-primary text-decoration-none"
                    style={{ textDecoration: "none" }}
                    aria-label="Healthcare Provider Registration"
                  >
                    {t("header_btn_2")}
                  </NavLink>
                </div>
              </div>
            )}
            {isAuth && mfaEnabled ? (
              <button
                className="btn btn-primary px-5 py-2 bgc-primary"
                onClick={goToDashboard}
                id="sign-in-btn__homepage"
                aria-label="Go to dashboard"
              >
                {t("dashboard")}
              </button>
            ) : (
              <button
                className="btn btn-primary px-5 py-2"
                onClick={() => {
                  navigate("/users/login");
                }}
                id="sign-in-btn__homepage"
                aria-label="Sign In"
              >
                {t("prac_register_sign_in_text")}
              </button>
            )}

            {(!isAuth && isCA) && (
              <div className="ms-3">
                <LanguageMenu />
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="bg-transparent position-absolute w-100"
        style={{
          left: isSmallDevice ? "0%" : "50%",
          transform: isSmallDevice ? "unset" : "translateX(-50%)",
        }}
      >
        <NavButtons show="all" />
      </div>
    </nav>
  );
}
