import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import "../styles/PG4/language_menu.scss";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const menuVariants = {
    hidden: { opacity: 0, scale: 0.9, y: -10 },
    visible: {
        opacity: 1,
        scale: 1,
        y: 0,
        transition: {
            ease: [0.25, 0.1, 0.25, 1],
            duration: 0.1,
            when: "beforeChildren",
            staggerChildren: 0.05
        }
    },
    exit: { opacity: 0, scale: 0.9, y: -10, transition: { duration: 0.2, ease: [0.25, 0.1, 0.25, 1] } }
};

const itemVariants = {
    hidden: { opacity: 0, y: -5 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            ease: [0.25, 0.1, 0.25, 1],
            duration: 0.2
        }
    }
};

export default function LanguageMenu() {
    const { i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const langMap = { ca: "EN", fr: "FR" };
    const [currentLang, setCurrentLang] = useState(i18n.language || "ca");
    const menuRef = useRef(null);
    const buttonRef = useRef(null);

    useEffect(() => {
        console.log({ currentLang });
    }, [currentLang]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                setOpen(false);
            }
        };

        if (open) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open]);

    const handleLanguageChange = async (lang) => {
        const prevLang = i18n.language;
        setError(null);
        setIsLoading(true);
        setCurrentLang(lang);
        try {
            await i18n.changeLanguage(lang);
            cookies.set("i18next", lang, { path: "/" });
        } catch (e) {
            await i18n.changeLanguage(prevLang);
            setError("Unable to change language");
        }
        setIsLoading(false);
        setOpen(false);
        buttonRef.current.focus(); // Return focus to the toggle button
    };

    return (
        <div className="language-switcher-container">
            <button
                ref={buttonRef}
                className={`lang-button text-uppercase ${open ? "active" : ""}`}
                onClick={() => !isLoading && setOpen(!open)}
                aria-label="Toggle language menu"
                aria-expanded={open}
                aria-haspopup="menu"
                aria-controls="language-menu"
                disabled={isLoading}
                onKeyDown={(e) => {
                    if (e.key === 'Escape' && open) {
                        setOpen(false);
                        buttonRef.current.focus();
                    }
                }}
            >
                {isLoading ? <span className="spinner" aria-hidden="true"></span> : langMap[currentLang]}
                {!isLoading && <span className={`caret-icon ${open ? "open" : ""}`} aria-hidden="true"></span>}
            </button>
            <AnimatePresence>
                {open && !isLoading && (
                    <motion.div
                        id="language-menu"
                        className="lang-menu"
                        variants={menuVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        role="menu"
                        aria-label="Language selection"
                        ref={menuRef}
                        tabIndex="-1"
                        onKeyDown={(e) => {
                            if (e.key === 'Escape') {
                                setOpen(false);
                                buttonRef.current.focus();
                            }
                        }}
                    >
                        <motion.button
                            className={`lang-option ${currentLang === "ca" ? "active" : ""}`}
                            onClick={() => handleLanguageChange("ca")}
                            role="menuitemradio"
                            aria-checked={currentLang === "ca"}
                            variants={itemVariants}
                            tabIndex="0"
                        >
                            EN
                        </motion.button>
                        <motion.button
                            className={`lang-option ${currentLang === "fr" ? "active" : ""}`}
                            onClick={() => handleLanguageChange("fr")}
                            role="menuitemradio"
                            aria-checked={currentLang === "fr"}
                            variants={itemVariants}
                            tabIndex="0"
                        >
                            FR
                        </motion.button>
                    </motion.div>
                )}
            </AnimatePresence>
            {error && <div className="lang-error" role="alert">{error}</div>}
        </div>
    );
}
 