import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import LoadIframe from "./LoadIframe";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getPG4ReportId, getQproToken } from "../store/actions/qpro";
import { encode } from "url-safe-base64";
import L_QproPage from "./LoadingEffectComponent/Dashboard/Practice/L_QproPage";
import toast from "react-hot-toast";

export const QproPage = (cardInfo) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  console.log(location.state);

  // redux actions
  const dispatch = useDispatch();
  const QRPO_WEB_HOST =
    process.env.REACT_APP_QPRO_WEB_HOST + "embed/auth?tenant=PG3";
  const [showLoader, setshowLoader] = useState(true);
  const token = useSelector((state) => state.auth.token);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const language = useSelector((state) => state.auth.language);
  const qpro_token = (token) =>
    dispatch(
      getQproToken(
        token,
        location.state.session_id,
        location.state.qpro_questionnaire_id,
        currentPracticeID,
        currentPractice
      )
    );
  const qpro_session_report_id = (token) =>
    dispatch(
      getPG4ReportId(
        token,
        location.state.session_id,
        currentPracticeID,
        currentPractice
      )
    );

  const [appURL, setAppURL] = useState("");

  const handleMessageActions = async (event) => {
    if (String(event?.data?.source).includes("qpro")) {
      if (event.data.action === "navigation") {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 750);
      }
      if (event.data.action === "submission") {
        const response = {};
        if (event.data.status === "Status.COMPLETED") {
          navigate("/patient/dashboard", { replace: true, state: { r_id: 123 } });
          toast.success("Questionnaire Completed!");
        }
        if (event.data.status === "Status.DATA_PUSHED") {
          setshowLoader(true);
          const response = await qpro_session_report_id(token);
          if (!response.error) {
            navigate(
              "/patient/msq/status?report_id=" +
              response?.payload?.recommendation_report_id
            );
          } else {
            navigate("/patient/dashboard", { replace: true, state: { r_id: 123 } });
            toast.error("Something went wrong!!!");
          }
        } else if (event.data.status === "Status.ERROR") {
          navigate("/patient/dashboard", { replace: true, state: { r_id: 123 } });
          toast.error("Something went wrong!!!");
        }

      }
    }
  };

  const setupQPRO = async () => {
    const response = await qpro_token(token);
    console.log({ response });
    if (!response.error) {
      setAppURL(
        QRPO_WEB_HOST + "&token=" + encode(btoa(response?.payload?.qpro_token))
      );
      setshowLoader(false);
    } else {
      console.log("error----------{response}", { response });
    }
    window.addEventListener("message", handleMessageActions);
  };

  useEffect(() => {
    setupQPRO();
    return () => {
      window.removeEventListener("message", handleMessageActions);
    };
  }, []);

  return (
    <div className="my-5">
      {showLoader ? (
        <L_QproPage />
      ) : (
        <div style={{ minHeight: "100vh" }}>
          <Helmet>
            <title>
              {" "}
              {language !== "en_gb" ? "PureInsight™" : "PureGenomics®"} - QPRO{" "}
            </title>
          </Helmet>
          {appURL && (
            <main className="container-xxl">
              <section
                className="d-flex justify-content-between flex-column flex-lg-row"
                id="stt-ref"
              >
                <div className="pg-title my-3 textc-primary__p w-75">
                  {t(location?.state?.heading) || "Multi-Symptom Questionnaire"}
                </div>
                <div className="my-auto d-none d-lg-block">
                  <button
                    className="pg4-btn pg4-btn_outline px-4"
                    onClick={() =>
                      navigate("/patient/dashboard", { replace: true })
                    }
                  >
                    {t("return_to_db")}
                  </button>
                </div>
              </section>
              <LoadIframe
                src={appURL}
                height="100%"
                width="100%"
                scrolling="yes"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
              />
            </main>
          )}
        </div>
      )}
    </div>
  );
};

export default QproPage;
