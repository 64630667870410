import { useState, useEffect, useRef } from "react";
import PgAccordion from "../PgAccordion";
import { useNavigate } from "react-router-dom";
import ExternalSitePrompt from "../ExternalSitePrompt";
import DOMPurify from "dompurify";
import { CAPatientFAQData, CAPatientFAQTitle, FRPatientFAQData, FRPatientFAQTitle } from "../Provider/ca_faq_data";
import { useTranslation } from "react-i18next";
import { isExternal } from "../../../methods";

function CAPatientFAQ() {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [showExternalSiteModal, setShowExternalSiteModal] = useState(false);
  const [externalSiteURL, setExternalSiteURL] = useState("");
  const faqContainerRef = useRef(null);
  const faqData = (i18n.language === "fr" ? FRPatientFAQData : CAPatientFAQData) || [];


  const handleExternalLinkClick = (url) => {
    setExternalSiteURL(url);
    setShowExternalSiteModal(true);
  };


  const handleClick = (event) => {
    const target = event.target;

    const anchor = target.closest("a");
    const excludedProtocols = ["mailto:", "tel:", "sms:", "ftp:", "news:", "irc:"];
    if (anchor && anchor.href) {
      const href = anchor.getAttribute("href");
      if (excludedProtocols.some(protocol => href.startsWith(protocol))) return;
      if (isExternal(href)) {
        event.preventDefault();
        handleExternalLinkClick(href);
      } else {
        event.preventDefault();
        navigate(href);
      }
    }
  };

  useEffect(() => {
    const container = faqContainerRef.current;

    if (container) {
      container.addEventListener("click", handleClick);
    }

    return () => {
      if (container) {
        container.removeEventListener("click", handleClick);
      }
    };
  }, [navigate]);

  return (
    <div className="py-5">
      {showExternalSiteModal && (
        <ExternalSitePrompt
          showModal={showExternalSiteModal}
          externalSiteLink={externalSiteURL}
          onCancel={() => setShowExternalSiteModal(false)}
          onProceed={() => {
            window.open(externalSiteURL, "_blank", "noopener,noreferrer");
            setShowExternalSiteModal(false);
          }}
        />
      )}
      <div className="container-xxl">
        <div class="pgs-title my-5 textc-primary__p lh-base" dangerouslySetInnerHTML={{ __html: i18n.language === "fr" ? FRPatientFAQTitle : CAPatientFAQTitle }} />

        <div ref={faqContainerRef}>
          {faqData.map((section, sectionIndex) => {
            const { section: sectionTitle = "", faqs = [] } = section || {};
            return (
              <div key={sectionIndex}>
                <div className="pgs-heading mt-5 mb-3 textc-primary__p" dangerouslySetInnerHTML={{ __html: sectionTitle }} />
                {faqs.map((faq, faqIndex) => (
                  <PgAccordion
                    key={faqIndex}
                    userType="Patient"
                    question={faq.question}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(faq.answer),
                      }}
                    />
                  </PgAccordion>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CAPatientFAQ;
